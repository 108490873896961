export interface PermissionsEntity {
  canRead: boolean;
  canWrite: boolean;
  canEdit: boolean;
  canDelete: boolean;
}

export class PermissionsDto {
  canDelete: boolean;
  canEdit: boolean;
  canWrite: boolean;
  canRead: boolean;

  constructor(permissionsEntity: PermissionsEntity) {
    this.canRead = permissionsEntity.canRead;
    this.canWrite = permissionsEntity.canWrite;
    this.canEdit = permissionsEntity.canEdit;
    this.canDelete = permissionsEntity.canDelete;
  }

  static createDtoFromEntity(
    permissionsEntity: PermissionsEntity,
  ): PermissionsDto {
    return new PermissionsDto(permissionsEntity);
  }
}
