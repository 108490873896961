import {
  SUPPORTED_TIMEZONE_VALUES,
  SupportedTimezoneValues,
} from '../../constants/timezones';

export interface SchoolEntity {
  schoolId: string;
  schoolName: string;
  contactInfo: string;
  numberOfStudents: number;
  currentMTSSFocus: string;
  existingChallenges: string;
  additionalInformation: string;
  timezoneId: string;
}

export class SchoolDto {
  timezoneId: SupportedTimezoneValues;
  additionalInformation: string;
  existingChallenges: string;
  currentMTSSFocus: string;
  numberOfStudents: number;
  contactInfo: string;
  schoolName: string;
  schoolId: string;

  constructor(schoolEntity: SchoolEntity) {
    this.schoolId = schoolEntity.schoolId;
    this.schoolName = schoolEntity.schoolName;
    this.contactInfo = schoolEntity.contactInfo;
    this.numberOfStudents = schoolEntity.numberOfStudents;
    this.currentMTSSFocus = schoolEntity.currentMTSSFocus;
    this.existingChallenges = schoolEntity.existingChallenges;
    this.additionalInformation = schoolEntity.additionalInformation;
    // Validate if timezoneId is supported
    if (
      SUPPORTED_TIMEZONE_VALUES.includes(
        schoolEntity.timezoneId as SupportedTimezoneValues,
      )
    ) {
      this.timezoneId = schoolEntity.timezoneId as SupportedTimezoneValues;
    } else {
      // default to America/Los_Angeles for new schools
      this.timezoneId = 'America/Los_Angeles';
      // TODO exception handling
      if (schoolEntity.timezoneId) {
        console.error('Invalid timezoneId:', schoolEntity.timezoneId);
      }
    }
  }

  static createDtoFromEntity(schoolEntity: SchoolEntity): SchoolDto {
    return new SchoolDto(schoolEntity);
  }
}
