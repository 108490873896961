import { PermissionsDto } from '../api/dtos/permissions.dto';
import { ResourceTypePermissionsDto } from '../api/dtos/resourceTypePermissions.dto';

export enum ResourceTypes {
  STUDENT_ASSESSMENTS = 'studentAssessments',
  STUDENT_INTERVENTIONS = 'studentInterventions',
  STUDENT_BEHAVIOR_EVENTS = 'studentBehavioralEvents',
  STUDENT_INFO = 'studentInfo',
  STUDENT_CHAT = 'studentChat',
  SCHOOL_CHAT = 'schoolChat',
  SCHOOL_INFO = 'schoolInfo',
  SCHOOL_DASHBOARD = 'schoolDashboard',
  SCHOOL_INSIGHTS = 'schoolInsights',
  PERMISSION_MANAGEMENT = 'permissionManagement',
  SETTINGS_CONFIG_MANAGEMENT = 'configManagement',
  USER_ACCESS_MANAGEMENT = 'userAccessManagement',
}

export const allAvailableResourceTypes = Object.values(ResourceTypes);

export const resourceTypeFriendlyNameMap = new Map<ResourceTypes, string>([
  [ResourceTypes.STUDENT_INTERVENTIONS, 'Student Interventions'],
  [
    ResourceTypes.STUDENT_BEHAVIOR_EVENTS,
    'Student Social-Emotional and Behavior Events',
  ],
  [ResourceTypes.STUDENT_ASSESSMENTS, 'Student Assessments'],
  [ResourceTypes.STUDENT_CHAT, 'Student Assistant Chat'],
  [ResourceTypes.STUDENT_INFO, 'Student Information'],
  [ResourceTypes.SCHOOL_INFO, 'School Information'],
  [ResourceTypes.SCHOOL_DASHBOARD, 'School Dashboard'],
  [ResourceTypes.SCHOOL_INSIGHTS, 'School Insights'],
  [ResourceTypes.SCHOOL_CHAT, 'MTSS Assistant Chat'],
  [ResourceTypes.PERMISSION_MANAGEMENT, 'Permission Management'],
  [ResourceTypes.USER_ACCESS_MANAGEMENT, 'User Access Management'],
  [ResourceTypes.SETTINGS_CONFIG_MANAGEMENT, 'Config Management Settings'],
]);

const defaultPermissions: PermissionsDto = {
  canRead: false,
  canWrite: false,
  canEdit: false,
  canDelete: false,
};

export const studentResourceTypePermissionsDtos: ResourceTypePermissionsDto[] =
  [
    {
      resourceType: ResourceTypes.STUDENT_INFO,
      permissions: { ...defaultPermissions },
    },
    {
      resourceType: ResourceTypes.STUDENT_ASSESSMENTS,
      permissions: { ...defaultPermissions },
    },
    {
      resourceType: ResourceTypes.STUDENT_CHAT,
      permissions: { ...defaultPermissions },
    },
    {
      resourceType: ResourceTypes.STUDENT_INTERVENTIONS,
      permissions: { ...defaultPermissions },
    },
    {
      resourceType: ResourceTypes.STUDENT_BEHAVIOR_EVENTS,
      permissions: { ...defaultPermissions },
    },
  ];

export const adminResourceTypePermissionsDtos: ResourceTypePermissionsDto[] = [
  {
    resourceType: ResourceTypes.USER_ACCESS_MANAGEMENT,
    permissions: { ...defaultPermissions },
  },
  {
    resourceType: ResourceTypes.PERMISSION_MANAGEMENT,
    permissions: { ...defaultPermissions },
  },
  {
    resourceType: ResourceTypes.SETTINGS_CONFIG_MANAGEMENT,
    permissions: { ...defaultPermissions },
  },
];
