import { Grid2, Paper } from '@mui/material';
import AntecedentsPieChart from './components/AntecedentsPieChart';
import { ConsequenceDistributionPieChart } from './components/ConsequenceDistributionPieChart';
import TimeSeriesPieChart from './components/TimeSeriesPieChart';
import { BehaviorDashboardDto } from '../../api/dtos/behaviorDashboard.dto';

type DashboardPieChartsProps = {
  selectedBehaviorType: string;
  sebDashboardData: BehaviorDashboardDto | undefined;
  isDataLoading: boolean;
};

const DashboardPieCharts: React.FC<DashboardPieChartsProps> = ({
  selectedBehaviorType,
  sebDashboardData,
  isDataLoading,
}) => {
  if (!selectedBehaviorType) {
    return (
      <Paper elevation={4} sx={{ padding: { xs: 1, lg: 3 }, marginBottom: 4 }}>
        <Grid2
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid2 size={{ xs: 12, lg: 5 }}>
            <TimeSeriesPieChart
              data={sebDashboardData?.timeSeriesDistribution?.data ?? []}
              isDataLoading={isDataLoading}
            />
          </Grid2>
        </Grid2>
      </Paper>
    );
  } else {
    return (
      <Paper elevation={4} sx={{ padding: { xs: 1, lg: 3 }, marginBottom: 4 }}>
        <Grid2
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid2 size={{ xs: 12, lg: 5 }}>
            <AntecedentsPieChart
              data={sebDashboardData?.antecedentDistribution?.data ?? []}
              isDataLoading={isDataLoading}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 5 }}>
            <ConsequenceDistributionPieChart
              isDataLoading={isDataLoading}
              data={sebDashboardData?.consequenceDistribution?.data ?? []}
            />
          </Grid2>
        </Grid2>
      </Paper>
    );
  }
};

export default DashboardPieCharts;
