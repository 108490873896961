import React, { memo } from 'react';
import InsightSummaryCard from './InsightSummaryCard.component';
import { ThreeNumberHighlightInsightSummaryCardDto } from '../../../api/dtos/threeNumberHighlightInsightSummaryCard.dto';
import { InsightDetailsDto } from '../../../api/dtos/insightDetails.dto';
import NoDataAvailableFallbackCard from './NoDataAvailableFallbackCard.component';

interface ThreeNumberHighlightInsightSummaryCardProps {
  insight?: ThreeNumberHighlightInsightSummaryCardDto;
  insightDetails?: InsightDetailsDto;
}

export const ThreeNumberHighlightInsightSummaryCard: React.FC<ThreeNumberHighlightInsightSummaryCardProps> =
  memo(function ThreeNumberHighlightInsightSummaryCard({
    insight,
    insightDetails,
  }) {
    if (!insight || !insightDetails) {
      return <NoDataAvailableFallbackCard />;
    }

    return (
      <InsightSummaryCard
        title={insight?.title || 'Unknown Title'}
        cards={[
          {
            title: insight?.numberTitle1 || 'Unknown',
            value: insight?.number1 ?? 'N/A',
          },
          {
            title: insight?.numberTitle2 || 'Unknown',
            value: insight?.number2 ?? 'N/A',
          },
          {
            title: insight?.numberTitle3 || 'Unknown',
            value: insight?.number3 ?? 'N/A',
          },
        ]}
        insightDetails={insightDetails}
      />
    );
  });

ThreeNumberHighlightInsightSummaryCard.displayName =
  'ThreeNumberHighlightInsightSummaryCard';

export default ThreeNumberHighlightInsightSummaryCard;
