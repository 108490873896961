import { useQuery } from '@tanstack/react-query';
import intellitierAxios from '../../lib/intellitierAxios';
import {
  BehaviorDashboardDto,
  BehaviorDashboardEntity,
} from '../dtos/behaviorDashboard.dto';

export function useGetSebDashboardData(
  schoolId: string,
  startDate: string,
  endDate: string,
  behaviorType: string = '',
  intensity: string = '',
) {
  return useQuery({
    queryKey: [
      'schools',
      schoolId,
      'dashboard',
      'seb',
      startDate,
      endDate,
      behaviorType,
      intensity,
    ],
    queryFn: async () => {
      const params = new URLSearchParams();

      if (startDate) params.append('start', startDate);
      if (endDate) params.append('end', endDate);
      if (behaviorType) params.append('behavior', behaviorType);
      if (intensity) params.append('intensity', intensity);

      const { data } = await intellitierAxios.get<BehaviorDashboardDto>(
        `/schools/${schoolId}/dashboard/seb?${params.toString()}`,
        {
          transformResponse: [
            (data) => {
              const behaviorDashboardEntity: BehaviorDashboardEntity =
                JSON.parse(data);
              const behaviorDashboardDto =
                BehaviorDashboardDto.createDtoFromEntity(
                  behaviorDashboardEntity,
                );

              return behaviorDashboardDto;
            },
          ],
        },
      );
      return data;
    },
    enabled: !!schoolId && !!startDate && !!endDate,
    staleTime: 2 * 60 * 1000, // 2 min: Data considered fresh for 2 minutes
  });
}
