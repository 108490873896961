import React from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { BehaviorDashboardDto } from '../../../api/dtos/behaviorDashboard.dto';
import { useChartDataAndConfig } from '../hooks/useChartDataAndConfig';
import { Typography, useTheme } from '@mui/material';
import { CenteredPageCircularProgress } from '../../../components/CenteredPageCircularProgress';
import ChartTooltip from './ChartTooltip';

type StackedBarChartProps = {
  data?: BehaviorDashboardDto;
  xKey: string;
  title?: string;
  height?: number;
  width?: string | number;
  isDataLoading: boolean;
};

export const StackedBarChart: React.FC<StackedBarChartProps> = ({
  data,
  xKey,
  title,
  height = 400,
  width = '100%',
  isDataLoading = false,
}) => {
  const { transformedData, configForData } = useChartDataAndConfig(data);
  const theme = useTheme();

  const ChartContent = () => {
    if (isDataLoading) {
      return <CenteredPageCircularProgress withTopMargin />;
    }

    if (transformedData.length === 0) {
      return (
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ fontWeight: 'bold' }}
          textAlign="center"
        >
          No data available
        </Typography>
      );
    }

    return (
      <ResponsiveContainer width={width} height={height}>
        <RechartsBarChart
          data={transformedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis
            dataKey={xKey}
            stroke={theme.palette.text.primary}
            tick={{ fill: theme.palette.text.primary }}
            domain={['auto', 'auto']}
          />
          <YAxis
            allowDecimals={false}
            domain={['dataMin', (dataMax: number) => dataMax]}
            stroke={theme.palette.text.primary}
            tick={{ fill: theme.palette.text.primary }}
          />
          <Tooltip content={<ChartTooltip />} />
          <Legend />
          {configForData.map((bar, index) => (
            <Bar
              key={index}
              name={bar?.name}
              dataKey={bar.dataKey}
              fill={bar.fill}
              stackId="a"
            />
          ))}
        </RechartsBarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div style={{ width: '100%', margin: '20px 0' }}>
      {title && (
        <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>{title}</h3>
      )}
      <ChartContent />
    </div>
  );
};
