import { SeriesEntryDto, SeriesEntryEntity } from './seriesEntry.dto';

export interface DataPoint2DEntity {
  key: string;
  seriesEntries: SeriesEntryEntity[];
}

export class DataPoint2DDto {
  key: string;
  seriesEntries: SeriesEntryDto[];

  constructor(dataPoint2DEntity: DataPoint2DEntity) {
    this.key = dataPoint2DEntity.key;
    this.seriesEntries = dataPoint2DEntity.seriesEntries.map(
      (entry) => new SeriesEntryDto(entry),
    );
  }

  static createDtoFromEntity(
    dataPoint2DEntity: DataPoint2DEntity,
  ): DataPoint2DDto {
    return new DataPoint2DDto(dataPoint2DEntity);
  }
}
