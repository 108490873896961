import { toSafeKey } from '../../features/Dashboard/helpers/objectKeyHelper';
import { DataPoint2DDto } from './dataPoint2.dto';
import { SeriesEntryDto } from './seriesEntry.dto';

export interface TransformedChartDataEntity {
  name: string;
  [key: string]: number | string; // Supports dynamic numerical keys (e.g., "uv", "pv", "amt")
}

export class TransformedChartDataDto {
  name: string;
  [key: string]: number | string; // Supports dynamic keys for different data series

  constructor(transformedChartDataEntity: TransformedChartDataEntity) {
    this.name = transformedChartDataEntity.name;
    Object.keys(transformedChartDataEntity).forEach((key) => {
      if (key !== 'name') {
        this[key] = transformedChartDataEntity[key];
      }
    });
  }

  static createDtoFromEntity(
    transformedChartDataEntity: TransformedChartDataEntity,
  ): TransformedChartDataDto {
    return new TransformedChartDataDto(transformedChartDataEntity);
  }

  static createDtosFromEntities(
    entities: TransformedChartDataEntity[],
  ): TransformedChartDataDto[] {
    return entities.map((entity) => new TransformedChartDataDto(entity));
  }
}

export function transformApiDataToChartData(
  apiData: DataPoint2DDto[],
): TransformedChartDataDto[] {
  return apiData.map((item: DataPoint2DDto) => {
    const transformedEntry: TransformedChartDataEntity = {
      name: item.key, // Keep original key for Recharts
    };

    item.seriesEntries.forEach((entry: SeriesEntryDto) => {
      // Convert label to a safe object key
      const safeLabelKey = toSafeKey(entry.label);
      transformedEntry[safeLabelKey] = entry.value;
    });

    return TransformedChartDataDto.createDtoFromEntity(transformedEntry);
  });
}
