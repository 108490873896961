export interface TwoNumberHighlightInsightSummaryCardEntity {
  title: string;
  cardType: string;
  numberTitle1: string;
  number1: number;
  numberTitle2: string;
  number2: number;
}

export class TwoNumberHighlightInsightSummaryCardDto {
  title: string;
  cardType: string;
  numberTitle1: string;
  number1: number;
  numberTitle2: string;
  number2: number;

  constructor(entity: TwoNumberHighlightInsightSummaryCardEntity) {
    this.title = entity.title;
    this.cardType = entity.cardType;
    this.numberTitle1 = entity.numberTitle1;
    this.number1 = entity.number1;
    this.numberTitle2 = entity.numberTitle2;
    this.number2 = entity.number2;
  }

  static createDtoFromEntity(
    entity: TwoNumberHighlightInsightSummaryCardEntity,
  ): TwoNumberHighlightInsightSummaryCardDto {
    return new TwoNumberHighlightInsightSummaryCardDto(entity);
  }
}
