import React, { memo } from 'react';
import { TextHighlightInsightSummaryCardDto } from '../../../api/dtos/textHighlightInsightSummaryCard.dto';
import InsightSummaryCard from './InsightSummaryCard.component';
import { InsightDetailsDto } from '../../../api/dtos/insightDetails.dto';
import NoDataAvailableFallbackCard from './NoDataAvailableFallbackCard.component';

interface TextHighlightInsightSummaryCardProps {
  insight?: TextHighlightInsightSummaryCardDto;
  insightDetails?: InsightDetailsDto;
}

export const TextHighlightInsightSummaryCard: React.FC<TextHighlightInsightSummaryCardProps> =
  memo(function TextHighlightInsightSummaryCard({ insight, insightDetails }) {
    if (!insight || !insightDetails) {
      return <NoDataAvailableFallbackCard />;
    }

    return (
      <InsightSummaryCard
        title={insight?.title || 'Unknown Title'}
        cards={[{ title: 'Highlight', value: insight?.body || 'N/A' }]}
        insightDetails={insightDetails}
      />
    );
  });

TextHighlightInsightSummaryCard.displayName = 'TextHighlightInsightSummaryCard';

export default TextHighlightInsightSummaryCard;
