import intellitierAxios from '../../lib/intellitierAxios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FileDetailsDto, FileDetailsEntity } from '../dtos/fileDetails.dto';
import { DateUtilites } from '../../lib/intellitierDayJs';

export function useGetStudentAssessmentFileDetails(
  studentId: string | undefined,
  schoolId: string,
  assetId: string | undefined,
) {
  return useQuery({
    queryKey: [
      'schools',
      schoolId,
      'students',
      studentId,
      'assessments',
      assetId,
      'fileDetails',
    ],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<FileDetailsDto>(
        `/schools/${schoolId}/students/${studentId}/assessments/fileDetails?assetId=${assetId}`,
        {
          transformResponse: [
            (data) => {
              const studentEntity: FileDetailsEntity = JSON.parse(data);
              return FileDetailsDto.createDtoFromEntity(studentEntity);
            },
          ],
        },
      );
      return data;
    },
    enabled: !!studentId && !!schoolId && !!assetId,
  });
}

export function useGetStudentAssessmentDownload() {
  return useMutation({
    mutationFn: async ({
      studentId,
      schoolId,
      assetId,
    }: {
      studentId: string;
      schoolId: string;
      assetId: string;
    }) => {
      if (!studentId || !schoolId || !assetId) {
        throw new Error('Missing parameters for download.');
      }

      const { data } = await intellitierAxios.get<Blob>(
        `/schools/${schoolId}/students/${studentId}/assessments/download?assetId=${assetId}`,
        {
          responseType: 'blob',
        },
      );
      return data;
    },
  });
}

// todo update this with file details
export interface StudentAssessmentUploadProps {
  fileUploadDetails: {
    file: File;
    assetId: string | undefined;
    assetType: string[];
    assetName: string;
    assetDate: string;
  };
  forceUpload: boolean;
}

export function useUploadStudentAssessment(
  studentId: string,
  schoolId: string,
  onSuccessCb: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      fileUploadDetails,
      forceUpload,
    }: StudentAssessmentUploadProps) => {
      // Create a FormData object
      const formData = new FormData();

      // Append the fileUploadDetails fields to formData
      formData.append('file', fileUploadDetails.file);
      formData.append('assetName', fileUploadDetails.assetName);
      formData.append(
        'assetDate',
        DateUtilites.formatIntoIsoString(fileUploadDetails.assetDate),
      );

      // Append assetType as multiple values if it is an array
      if (Array.isArray(fileUploadDetails.assetType)) {
        formData.append('assetType', fileUploadDetails.assetType.join(','));
      } else {
        formData.append('assetType', fileUploadDetails.assetType);
      }

      if (fileUploadDetails.assetId) {
        formData.append('assetId', fileUploadDetails.assetId);
      }

      return intellitierAxios.post<FileDetailsDto>(
        `/schools/${schoolId}/students/${studentId}/assessments/upload?force=${forceUpload}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [
          'schools',
          schoolId,
          'students',
          studentId,
          'assessments',
          data?.data?.assetId ?? '',
          'download',
        ],
      });
      onSuccessCb();
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

// todo update this with file details
export interface UpdateStudentAssessmentDetailsProps {
  fileDetails: FileDetailsDto;
}
export function useUpdateStudentAssessmentDetails(
  studentId: string,
  schoolId: string,
  onSuccessCb: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ fileDetails }: UpdateStudentAssessmentDetailsProps) => {
      return intellitierAxios.put<FileDetailsDto>(
        `/schools/${schoolId}/students/${studentId}/assessments/fileDetails`,
        {
          ...fileDetails,
        },
        {
          transformResponse: [
            (data) => {
              const fileDetailsEntity: FileDetailsEntity = JSON.parse(data);
              return FileDetailsDto.createDtoFromEntity(fileDetailsEntity);
            },
          ],
        },
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [
          'schools',
          schoolId,
          'students',
          studentId,
          'assessments',
          data?.data?.assetId ?? '',
        ],
      });
      onSuccessCb();
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useDeleteStudentAssessmentForStudent(
  studentId: string,
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (assetId: string) => {
      return intellitierAxios.delete(
        `/schools/${schoolId}/students/${studentId}/assessments?assetId=${assetId}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['studentWithHistory', studentId, schoolId],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}
