import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import intellitierAxios from '../../lib/intellitierAxios';
import { SchoolRolesDto, SchoolRolesEntity } from '../dtos/schoolRoles.dto';
import { RolePermissionsDto } from '../dtos/rolePermission.dto';
import {
  DetailedUserPermissionsDto,
  DetailedUserPermissionsEntity,
} from '../dtos/detailedUserPermissions.dto';
import { ResourceTypePermissionsDto } from '../dtos/resourceTypePermissions.dto';
import {
  PermissionRequestsDto,
  PermissionRequestsEntity,
} from '../dtos/permissionRequests.dto';
import {
  UserPermissionsDto,
  UserPermissionsEntity,
} from '../dtos/userPermissions.dto';
import { UserStudentPermissionsDto } from '../dtos/userStudentPermissions.dto';

export function useGetRolesForSchoolId(schoolId: string) {
  return useQuery({
    queryKey: ['permission', 'school', schoolId, 'roles'],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<SchoolRolesDto>(
        `/permission/school/${schoolId}/role`,
        {
          transformResponse: [
            (data) => {
              const schoolRolesEntity: SchoolRolesEntity = JSON.parse(data);
              const schoolsDto =
                SchoolRolesDto.createDtoFromEntity(schoolRolesEntity);
              schoolsDto.roles.sort((a, b) =>
                a.role.roleName.localeCompare(b.role.roleName),
              );

              return schoolsDto;
            },
          ],
        },
      );

      return data;
    },
    enabled: !!schoolId,
  });
}

export function useUpsertRoleForSchoolId(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (rolePermissionsDto: RolePermissionsDto) => {
      return intellitierAxios.post<SchoolRolesEntity>(
        `/permission/school/${schoolId}/role`,
        {
          ...rolePermissionsDto,
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['permission', 'school', schoolId, 'roles'],
      });
      queryClient.invalidateQueries({
        queryKey: ['permission', 'user', 'school', schoolId],
      });
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useDeleteRoleForSchoolId(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (roleName: string) => {
      return intellitierAxios.delete(
        `/permission/school/${schoolId}/role/${roleName}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['permission', 'school', schoolId, 'roles'],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useGetPermissionsForUserInSchool(schoolId: string) {
  return useQuery({
    queryKey: ['permission', 'user', 'school', schoolId],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<UserPermissionsDto>(
        `/permission/user/school/${schoolId}`,
        {
          transformResponse: [
            (data) => {
              const userPermissionsEntity: UserPermissionsEntity =
                JSON.parse(data);

              return UserPermissionsDto.createDtoFromEntity(
                userPermissionsEntity,
              );
            },
          ],
        },
      );

      return data;
    },
    enabled: !!schoolId,
  });
}

export function useGetDetailedPermissionsForUserInSchool(
  schoolId: string,
  userId: string,
) {
  return useQuery({
    queryKey: [
      'permission',
      'user',
      userId,
      'school',
      schoolId,
      'detailedPermissions',
    ],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<DetailedUserPermissionsDto>(
        `/permission/user/${userId}/school/${schoolId}/detailedPermissions`,
        {
          transformResponse: [
            (data) => {
              const detailedUserPermissionsEntity: DetailedUserPermissionsEntity =
                JSON.parse(data);

              return DetailedUserPermissionsDto.createDtoFromEntity(
                detailedUserPermissionsEntity,
              );
            },
          ],
        },
      );

      return data;
    },
    enabled: !!schoolId && !!userId,
  });
}

export function useUpsertRolesForUser(
  userId: string,
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (roleNames: string[]) => {
      return intellitierAxios.post(
        `/permission/user/${userId}/school/${schoolId}/roles`,
        {
          roleNames: roleNames,
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['permission', 'school', schoolId, 'roles'],
      });
      queryClient.invalidateQueries({
        queryKey: [
          'permission',
          'user',
          userId,
          'school',
          schoolId,
          'detailedPermissions',
        ],
      });
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useDeleteRoleForUser(
  userId: string,
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (roleName: string) => {
      return intellitierAxios.delete(
        `/permission/user/${userId}/school/${schoolId}/role/${roleName}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['permission', 'school', schoolId, 'roles'],
      });
      queryClient.invalidateQueries({
        queryKey: [
          'permission',
          'user',
          userId,
          'school',
          schoolId,
          'detailedPermissions',
        ],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useUpsertStudentPermissionsForUser(
  userId: string,
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      studentIds,
      resourceTypePermissions,
    }: {
      studentIds: string[];
      resourceTypePermissions: ResourceTypePermissionsDto[];
    }) => {
      return intellitierAxios.post(
        `/permission/user/${userId}/school/${schoolId}/students`,
        {
          userId: userId,
          studentIds,
          resourceTypePermissions,
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'permission',
          'user',
          userId,
          'school',
          schoolId,
          'detailedPermissions',
        ],
      });

      queryClient.invalidateQueries({
        queryKey: ['permission', 'user', 'school', schoolId],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useDeleteStudentForUser(
  userId: string,
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (studentId: string) => {
      return intellitierAxios.delete(
        `/permission/user/${userId}/school/${schoolId}/student/${studentId}`,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['permission', 'school', schoolId, 'roles'],
      });
      queryClient.invalidateQueries({
        queryKey: [
          'permission',
          'user',
          userId,
          'school',
          schoolId,
          'detailedPermissions',
        ],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useGetPermissionRequestsForSchool(schoolId: string) {
  return useQuery({
    queryKey: ['permission', 'school', schoolId, 'permissionRequests'],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<PermissionRequestsDto>(
        `/permission/school/${schoolId}/permissionRequest`,
        {
          transformResponse: [
            (data) => {
              const permissionRequestsEntity: PermissionRequestsEntity =
                JSON.parse(data);

              return PermissionRequestsDto.createDtoFromEntity(
                permissionRequestsEntity,
              );
            },
          ],
        },
      );

      return data;
    },
    enabled: !!schoolId,
  });
}

export function useApprovePermissionRequestForStudent(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      studentId,
      userId,
    }: {
      studentId: string;
      userId: string;
    }) => {
      return intellitierAxios.post(
        `/permission/user/${userId}/school/${schoolId}/student/${studentId}/approvePermissionRequest`,
        {},
      );
    },
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries({
        queryKey: [
          'permission',
          'user',
          userId,
          'school',
          schoolId,
          'detailedPermissions',
        ],
      });

      queryClient.invalidateQueries({
        queryKey: ['permission', 'school', schoolId, 'permissionRequests'],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useRejectPermissionRequestForStudent(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      studentId,
      userId,
    }: {
      studentId: string;
      userId: string;
    }) => {
      return intellitierAxios.post(
        `/permission/user/${userId}/school/${schoolId}/student/${studentId}/rejectPermissionRequest`,
        {},
      );
    },
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries({
        queryKey: [
          'permission',
          'user',
          userId,
          'school',
          schoolId,
          'detailedPermissions',
        ],
      });

      queryClient.invalidateQueries({
        queryKey: ['permission', 'school', schoolId, 'permissionRequests'],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useOverridePermissionRequestForStudent(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      studentId,
      resourceTypePermissions,
    }: {
      userId: string;
      studentId: string;
      resourceTypePermissions: ResourceTypePermissionsDto[];
    }) => {
      return intellitierAxios.post(
        `/permission/user/${userId}/school/${schoolId}/student/${studentId}/approvePermissionRequestWithOverrides`,
        {
          userId,
          studentId,
          resourceTypePermissions,
        },
      );
    },
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries({
        queryKey: [
          'permission',
          'user',
          userId,
          'school',
          schoolId,
          'detailedPermissions',
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ['permission', 'school', schoolId, 'permissionRequests'],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useRequestPermissionsForStudent(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userStudentPermissions,
    }: {
      userStudentPermissions: UserStudentPermissionsDto;
    }) => {
      return intellitierAxios.post(
        `/permission/user/school/${schoolId}/student/requestPermission`,
        { ...userStudentPermissions },
      );
    },
    onSuccess: (_, { userStudentPermissions }) => {
      queryClient.invalidateQueries({
        queryKey: [
          `/schools/${schoolId}/students/${userStudentPermissions.studentId}/history`,
        ],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}
