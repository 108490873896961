import { Theme } from '@mui/material';
import chroma from 'chroma-js';
import { fromSafeKey } from './objectKeyHelper';

// Define the structure of the returned color config
interface ChartColorConfig {
  dataKey: string;
  fill: string;
  stroke: string;
  name: string;
}

/**
 * Generates a unique color configuration for chart data keys.
 *
 * If there are fewer or equal data keys than base colors, it will use each base color directly.
 * If there are more data keys, it will cycle through available colors while ensuring uniqueness.
 *
 * @param theme - The application's theme containing base colors.
 * @param dataKeys - The keys representing different data series.
 * @returns An array of objects containing `dataKey`, `fill` color, and `name`.
 */
export function generateChartColors(
  theme: Theme,
  dataKeys: string[],
): ChartColorConfig[] {
  const baseColors: string[] = Object.values(theme.custom.chart);
  const uniqueColors = new Set<string>();
  const numBaseColors = baseColors.length;

  return dataKeys.map((key, index) => {
    let color: string;
    const name = fromSafeKey(key);

    // If data keys are fewer than or equal to base colors, assign directly
    if (dataKeys.length <= numBaseColors) {
      color = baseColors[index];
    } else {
      // Otherwise, cycle through base colors and adjust brightness if needed
      color = baseColors[index % numBaseColors];
      let shadeOffset = 0;

      while (uniqueColors.has(color)) {
        color = chroma(color)
          .brighten((shadeOffset % 5) - 2)
          .hex();
        shadeOffset++;
      }
    }

    uniqueColors.add(color);

    return { dataKey: key, fill: color, stroke: color, name };
  });
}
