import React from 'react';
import {
  AreaChart as RechartsAreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList,
} from 'recharts';
import { BehaviorDashboardDto } from '../../../api/dtos/behaviorDashboard.dto';
import { useTheme } from '@mui/material';
import { CenteredPageCircularProgress } from '../../../components/CenteredPageCircularProgress';
import { useChartDataAndConfig } from '../hooks/useChartDataAndConfig';
import ChartTooltip from './ChartTooltip';

type TotalsAreaChartProps = {
  data?: BehaviorDashboardDto;
  xKey: string;
  title?: string;
  height?: number;
  width?: string | number;
  isDataLoading: boolean;
  shouldShowTopLabels?: boolean;
};

export const TotalsAreaChart: React.FC<TotalsAreaChartProps> = ({
  data,
  xKey,
  title,
  height = 400,
  width = '100%',
  isDataLoading,
  shouldShowTopLabels,
}) => {
  const { transformedData, configForData: areas } = useChartDataAndConfig(data);
  const theme = useTheme();

  const ChartContent = () => {
    if (isDataLoading) {
      return <CenteredPageCircularProgress withTopMargin />;
    }

    if (transformedData.length === 0) {
      return (
        <p style={{ textAlign: 'center', color: '#888', fontWeight: 'bold' }}>
          No data available
        </p>
      );
    }

    return (
      <ResponsiveContainer width={width} height={height}>
        <RechartsAreaChart
          data={transformedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis
            dataKey={xKey}
            stroke={theme.palette.text.primary}
            tick={{ fill: theme.palette.text.primary }}
            domain={['auto', 'auto']}
          />
          <YAxis
            allowDecimals={false}
            domain={['dataMin', (dataMax: number) => dataMax]}
            stroke={theme.palette.text.primary}
            tick={{ fill: theme.palette.text.primary }}
          />
          <Tooltip content={<ChartTooltip />} />
          <Legend />
          {areas.map((area, index) => (
            <Area
              key={index}
              name={area.name}
              type="monotone"
              dataKey={area.dataKey}
              stroke={area.stroke}
              fill={area.fill}
              strokeWidth={5}
            >
              {shouldShowTopLabels && (
                <LabelList dataKey={area.dataKey} position="top" />
              )}
            </Area>
          ))}
        </RechartsAreaChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div style={{ width: '100%', margin: '20px 0' }}>
      {title && (
        <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>{title}</h3>
      )}
      <ChartContent />
    </div>
  );
};
