export interface InsightDetailsEntity {
  title: string;
  description: string;
  recommendation: string;
}

export class InsightDetailsDto {
  title: string;
  description: string;
  recommendation: string;

  constructor(entity: InsightDetailsEntity) {
    this.title = entity.title;
    this.description = entity.description;
    this.recommendation = entity.recommendation;
  }

  static createDtoFromEntity(entity: InsightDetailsEntity): InsightDetailsDto {
    return new InsightDetailsDto(entity);
  }
}
