import { Dayjs } from 'dayjs';
import { UserDto, UserEntity } from './user.dto';
import { DateUtilites } from '../../lib/intellitierDayJs';

export type AiAnalysisState = 'pending' | 'complete' | 'error';

export interface FileDetailsEntity {
  assetId: string;
  assetType: string[];
  assetName: string;
  fileName: string;
  assetDate: string;
  uploadedBy: UserEntity;
  aiAnalysisState: AiAnalysisState;
}

export class FileDetailsDto {
  aiAnalysisState: AiAnalysisState;
  uploadedBy?: UserDto;
  formattedAssetDate?: string;
  assetDate: Dayjs;
  fileName: string;
  assetName: string;
  assetType: string[];
  assetId: string;

  constructor(fileDetailsEntity: FileDetailsEntity) {
    this.assetId = fileDetailsEntity.assetId;
    this.assetType = fileDetailsEntity.assetType;
    this.assetName = fileDetailsEntity.assetName;
    this.fileName = fileDetailsEntity.fileName;
    this.assetDate = DateUtilites.createLocalTimeFromUtcDate(
      fileDetailsEntity.assetDate,
    );
    this.formattedAssetDate = DateUtilites.formatDateToLocalDateTime(
      this.assetDate,
    );
    this.aiAnalysisState = fileDetailsEntity.aiAnalysisState;

    // TODO when Jose returns this on put response, we can remove
    if (fileDetailsEntity.uploadedBy) {
      this.uploadedBy = UserDto.createDtoFromEntity(
        fileDetailsEntity.uploadedBy,
      );
    }
  }

  static createDtoFromEntity(
    fileDetailsEntity: FileDetailsEntity,
  ): FileDetailsDto {
    return new FileDetailsDto(fileDetailsEntity);
  }
}
