import { OneDChartDto, OneDChartEntity } from './oneDChart.dto';
import { TwoDChartDto, TwoDChartEntity } from './twoDChart.dto';

export interface BehaviorDashboardEntity {
  timeSeries: TwoDChartEntity;
  antecedentDistribution: OneDChartEntity;
  consequenceDistribution: OneDChartEntity;
  timeSeriesDistribution: OneDChartEntity;
}

export class BehaviorDashboardDto {
  timeSeries: TwoDChartDto;
  antecedentDistribution?: OneDChartDto;
  consequenceDistribution?: OneDChartDto;
  timeSeriesDistribution?: OneDChartDto;

  constructor(behaviorDashboardEntity: BehaviorDashboardEntity) {
    this.timeSeries = new TwoDChartDto(behaviorDashboardEntity.timeSeries);

    if (behaviorDashboardEntity.antecedentDistribution) {
      this.antecedentDistribution = new OneDChartDto(
        behaviorDashboardEntity.antecedentDistribution,
      );
    }

    if (behaviorDashboardEntity.consequenceDistribution) {
      this.consequenceDistribution = new OneDChartDto(
        behaviorDashboardEntity.consequenceDistribution,
      );
    }

    if (behaviorDashboardEntity.timeSeriesDistribution) {
      this.timeSeriesDistribution = new OneDChartDto(
        behaviorDashboardEntity.timeSeriesDistribution,
      );
    }
  }

  static createDtoFromEntity(
    behaviorDashboardEntity: BehaviorDashboardEntity,
  ): BehaviorDashboardDto {
    return new BehaviorDashboardDto(behaviorDashboardEntity);
  }
}
