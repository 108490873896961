export interface NumberHighlightInsightSummaryCardEntity {
  title: string;
  cardType: string;
  numberTitle: string;
  number: number;
}

export class NumberHighlightInsightSummaryCardDto {
  title: string;
  cardType: string;
  numberTitle: string;
  number: number;

  constructor(entity: NumberHighlightInsightSummaryCardEntity) {
    this.title = entity.title;
    this.cardType = entity.cardType;
    this.numberTitle = entity.numberTitle;
    this.number = entity.number;
  }

  static createDtoFromEntity(
    entity: NumberHighlightInsightSummaryCardEntity,
  ): NumberHighlightInsightSummaryCardDto {
    return new NumberHighlightInsightSummaryCardDto(entity);
  }
}
