import {
  Grid2,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { DateRanges } from './types';

type DashboardFiltersProps = {
  behaviorOptions: string[];
  intensityOptions: string[];
  selectedBehaviorType: string;
  setSelectedBehaviorType: (value: string) => void;
  selectedIntensity: string;
  setSelectedIntensity: (value: string) => void;
  selectedDateRange: keyof DateRanges;
  setSelectedDateRange: Dispatch<SetStateAction<keyof DateRanges>>;
  dateRanges: DateRanges;
};

export const DashboardFilters: React.FC<DashboardFiltersProps> = ({
  behaviorOptions,
  intensityOptions,
  selectedBehaviorType,
  setSelectedBehaviorType,
  selectedIntensity,
  setSelectedIntensity,
  selectedDateRange,
  setSelectedDateRange,
  dateRanges,
}) => {
  // Memoize the menu items
  const dateRangeItems = useMemo(
    () =>
      Object.keys(dateRanges).map((range) => (
        <MenuItem key={range} value={range}>
          {range}
        </MenuItem>
      )),
    [dateRanges],
  );

  const behaviorItems = useMemo(
    () =>
      behaviorOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      )),
    [behaviorOptions],
  );

  const intensityItems = useMemo(
    () =>
      intensityOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      )),
    [intensityOptions],
  );

  return (
    <Paper
      elevation={4}
      sx={{
        padding: {
          xs: 1.5,
          sm: 2,
          md: 3,
        },
        marginBottom: 4,
      }}
    >
      <Grid2 container spacing={2} alignItems="center" justifyContent="center">
        {/* Date Range */}
        <Grid2 size={{ xs: 12, md: 4 }}>
          <FormControl fullWidth>
            <InputLabel id="date-range-label">Date Range</InputLabel>
            <Select
              labelId="date-range-label"
              value={selectedDateRange}
              onChange={(e) =>
                setSelectedDateRange(e.target.value as keyof DateRanges)
              }
              label="Date Range"
              aria-labelledby="date-range-label"
              aria-describedby="date-range-description"
            >
              {dateRangeItems}
            </Select>
          </FormControl>
        </Grid2>

        {/* Behavior Type */}
        <Grid2 size={{ xs: 12, md: 4 }}>
          <FormControl fullWidth>
            <InputLabel id="behavior-type-label">Behavior Type</InputLabel>
            <Select
              labelId="behavior-type-label"
              value={selectedBehaviorType}
              onChange={(e) => setSelectedBehaviorType(e.target.value)}
              label="Behavior Type"
              aria-labelledby="behavior-type-label"
              aria-describedby="behavior-type-description"
              endAdornment={
                selectedBehaviorType && (
                  <ClearFilterButton
                    onClick={() => {
                      setSelectedBehaviorType('');
                      setSelectedIntensity('');
                    }}
                    ariaLabel="Clear behavior type"
                  />
                )
              }
            >
              {behaviorItems}
            </Select>
          </FormControl>
        </Grid2>

        {/* Intensity */}
        <Grid2 size={{ xs: 12, md: 4 }}>
          <FormControl fullWidth disabled={!selectedBehaviorType}>
            <InputLabel id="intensity-label">Behavior Intensity</InputLabel>
            <Select
              labelId="intensity-label"
              value={selectedIntensity}
              onChange={(e) => setSelectedIntensity(e.target.value)}
              label="Behavior Intensity"
              aria-labelledby="intensity-label"
              aria-describedby="intensity-description"
              endAdornment={
                selectedIntensity && (
                  <ClearFilterButton
                    onClick={() => setSelectedIntensity('')}
                    ariaLabel="Clear behavior intensity"
                  />
                )
              }
            >
              {intensityItems}
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>
    </Paper>
  );
};

const ClearFilterButton: React.FC<{
  onClick: () => void;
  ariaLabel: string;
}> = ({ onClick, ariaLabel }) => (
  <InputAdornment position="end" sx={{ marginRight: 1 }}>
    <Tooltip title="Clear selection" arrow>
      <IconButton size="small" onClick={onClick} aria-label={ariaLabel}>
        <ClearIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  </InputAdornment>
);
