import {
  ResourceTypePermissionsDto,
  ResourceTypePermissionsEntity,
} from './resourceTypePermissions.dto';
import { StudentDto, StudentEntity } from './student.dto';
import { UserDto, UserEntity } from './user.dto';

export interface DetailedUserStudentPermissionsEntity {
  user: UserEntity;
  student: StudentEntity;
  resourceTypePermissions: ResourceTypePermissionsEntity[];
}

export class DetailedUserStudentPermissionsDto {
  resourceTypePermissions: ResourceTypePermissionsDto[];
  student: StudentDto;
  user: UserDto;

  constructor(
    detailedUserStudentPermissionsEntity: DetailedUserStudentPermissionsEntity,
  ) {
    this.user = UserDto.createDtoFromEntity(
      detailedUserStudentPermissionsEntity.user,
    );
    this.student = StudentDto.createDtoFromEntity(
      detailedUserStudentPermissionsEntity.student,
    );
    this.resourceTypePermissions =
      detailedUserStudentPermissionsEntity.resourceTypePermissions
        .filter((resourceTypePermissionsEntity) =>
          Boolean(resourceTypePermissionsEntity.resourceType),
        )
        .map((resourceTypePermissionsEntity) =>
          ResourceTypePermissionsDto.createDtoFromEntity(
            resourceTypePermissionsEntity,
          ),
        );
  }

  static createDtoFromEntity(
    detailedUserStudentPermissionsEntity: DetailedUserStudentPermissionsEntity,
  ): DetailedUserStudentPermissionsDto {
    return new DetailedUserStudentPermissionsDto(
      detailedUserStudentPermissionsEntity,
    );
  }
}
