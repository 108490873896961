import { InsightDetailsDto, InsightDetailsEntity } from './insightDetails.dto';
import {
  NumberHighlightInsightSummaryCardDto,
  NumberHighlightInsightSummaryCardEntity,
} from './numberHighlightInsightSummaryCard.dto';
import {
  TextHighlightInsightSummaryCardDto,
  TextHighlightInsightSummaryCardEntity,
} from './textHighlightInsightSummaryCard.dto';
import {
  ThreeNumberHighlightInsightSummaryCardDto,
  ThreeNumberHighlightInsightSummaryCardEntity,
} from './threeNumberHighlightInsightSummaryCard.dto';
import {
  TwoNumberHighlightInsightSummaryCardDto,
  TwoNumberHighlightInsightSummaryCardEntity,
} from './twoNumberHighlightInsightSummaryCard.dto';

export interface SchoolInsightEntity {
  insightSummaryCard:
    | NumberHighlightInsightSummaryCardEntity
    | TextHighlightInsightSummaryCardEntity
    | TwoNumberHighlightInsightSummaryCardEntity
    | ThreeNumberHighlightInsightSummaryCardEntity;
  insightDetails: InsightDetailsEntity;
}

export class SchoolInsightDto {
  insightSummaryCard:
    | NumberHighlightInsightSummaryCardDto
    | TextHighlightInsightSummaryCardDto
    | TwoNumberHighlightInsightSummaryCardDto
    | ThreeNumberHighlightInsightSummaryCardDto;
  insightDetails: InsightDetailsDto;

  constructor(entity: SchoolInsightEntity) {
    this.insightSummaryCard = this.createSummaryCardDto(
      entity.insightSummaryCard,
    );
    this.insightDetails = new InsightDetailsDto(entity.insightDetails);
  }

  private createSummaryCardDto(
    summaryCard:
      | NumberHighlightInsightSummaryCardEntity
      | TextHighlightInsightSummaryCardEntity
      | TwoNumberHighlightInsightSummaryCardEntity
      | ThreeNumberHighlightInsightSummaryCardEntity,
  ) {
    switch (summaryCard.cardType) {
      case 'NUMBER_HIGHLIGHT':
        return NumberHighlightInsightSummaryCardDto.createDtoFromEntity(
          summaryCard as NumberHighlightInsightSummaryCardEntity,
        );
      case 'TEXT_HIGHLIGHT':
        return TextHighlightInsightSummaryCardDto.createDtoFromEntity(
          summaryCard as TextHighlightInsightSummaryCardEntity,
        );
      case 'TWO_NUMBER_HIGHLIGHT':
        return TwoNumberHighlightInsightSummaryCardDto.createDtoFromEntity(
          summaryCard as TwoNumberHighlightInsightSummaryCardEntity,
        );
      case 'THREE_NUMBER_HIGHLIGHT':
        return ThreeNumberHighlightInsightSummaryCardDto.createDtoFromEntity(
          summaryCard as ThreeNumberHighlightInsightSummaryCardEntity,
        );
      default:
        throw new Error('Invalid insight summary card type');
    }
  }

  static createDtoFromEntity(entity: SchoolInsightEntity): SchoolInsightDto {
    return new SchoolInsightDto(entity);
  }
}
