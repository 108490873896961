import axios from 'axios';
import { getAccessToken } from '../api/services/msGraph.service';

const DEFAULT_OPTIONS = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 45_000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const intellitierAxios = axios.create({ ...DEFAULT_OPTIONS });

// Incept request to use AUTH token / get silent token on each request
intellitierAxios.interceptors.request.use(
  async (config) => {
    try {
      const accessToken = await getAccessToken();
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    } catch (error) {
      // Handle errors retrieving access token
      return Promise.reject(error);
    }
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  },
);

export default intellitierAxios;
