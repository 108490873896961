export interface RoleEntity {
  roleName: string;
  systemRole: boolean;
}

export class RoleDto {
  systemRole: boolean;
  roleName: string;

  constructor(roleEntity: RoleEntity) {
    this.roleName = roleEntity.roleName;
    this.systemRole = roleEntity.systemRole;
  }

  static createDtoFromEntity(roleEntity: RoleEntity): RoleDto {
    return new RoleDto(roleEntity);
  }
}
