import { SeriesEntryDto, SeriesEntryEntity } from './seriesEntry.dto';

export interface OneDChartEntity {
  chartName: string;
  data: SeriesEntryEntity[];
}

export class OneDChartDto {
  chartName: string;
  data: SeriesEntryDto[];

  constructor(oneDChartEntity: OneDChartEntity) {
    this.chartName = oneDChartEntity.chartName;
    this.data = oneDChartEntity.data.map((entry) => new SeriesEntryDto(entry));
  }

  static createDtoFromEntity(oneDChartEntity: OneDChartEntity): OneDChartDto {
    return new OneDChartDto(oneDChartEntity);
  }
}
