import { StudentForAutocomplete } from '../../components/StudentSearch.component';
import { BehavioralEventDto } from '../dtos/behavioralEvent.dto';
import { InterventionDto } from '../dtos/intervention.dto';
import { SchoolDto, SchoolEntity } from '../dtos/school.dto';
import { StudentDto, StudentEntity } from '../dtos/student.dto';
import { UserDto, UserEntity } from '../dtos/user.dto';
import intellitierAxios from '../../lib/intellitierAxios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// TODO we should move this to its own file
export interface SchoolsWithStudentsEntity {
  school: SchoolDto;
  students: StudentDto[];
}

export function useGetSearchForStudentName(
  studentNameQuery: string,
  schoolIds: string[],
) {
  return useQuery({
    queryKey: ['schools', 'search', studentNameQuery, ...schoolIds],
    queryFn: async () => {
      const response = await intellitierAxios.post<StudentForAutocomplete[]>(
        `/schools/search`,
        {
          schoolIds: schoolIds,
          studentName: studentNameQuery,
        },
        {
          transformResponse: [
            (data) => {
              const studentsForAutocomplete: SchoolsWithStudentsEntity[] =
                JSON.parse(data);

              const transformedData = studentsForAutocomplete?.flatMap(
                (group) =>
                  group.students.map((student) => ({
                    id: student.studentId,
                    name: student.name || '',
                    studentNumber: student.studentNumber,
                    school: group.school.schoolName,
                    schoolId: group.school.schoolId,
                  })),
              );

              // since we want the names returned to be alphabetical, first we sort by name
              // Sort by name, then by student number if names are equal
              return transformedData.sort((a, b) => {
                const nameComparison = (a.name || '').localeCompare(
                  b.name || '',
                );
                if (nameComparison !== 0) {
                  return nameComparison;
                }
                return (a.studentNumber || '').localeCompare(
                  b.studentNumber || '',
                );
              });
            },
          ],
        },
      );

      return response.data;
    },
    enabled: !!studentNameQuery && !!schoolIds && schoolIds.length > 0,
  });
}

export function useGetSearchForUserEmail(
  userEmailQuery: string,
  schoolId: string,
) {
  return useQuery({
    queryKey: ['schools', 'searchUsers', userEmailQuery, schoolId],
    queryFn: async () => {
      const response = await intellitierAxios.post<UserDto[]>(
        `/schools/searchUsers`,
        {
          schoolId: schoolId,
          userEmail: userEmailQuery,
        },
        {
          transformResponse: [
            (data) => {
              const users: UserEntity[] = JSON.parse(data);

              const transformedData = users.map((user: UserEntity) =>
                UserDto.createDtoFromEntity(user),
              );

              return transformedData.sort((a, b) =>
                (a.email || '').localeCompare(b.email || ''),
              );
            },
          ],
        },
      );

      return response.data;
    },
    enabled: !!userEmailQuery && !!schoolId,
  });
}

export function useGetUsersForSchoolId(schoolId: string) {
  return useQuery({
    queryKey: ['schools', schoolId, 'users'],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<UserDto[]>(
        `/schools/${schoolId}/users`,
        {
          transformResponse: [
            (data) => {
              const userEntities: UserEntity[] = JSON.parse(data);
              return userEntities.map((userEntity) =>
                UserDto.createDtoFromEntity(userEntity),
              );
            },
          ],
        },
      );
      return data;
    },
    enabled: !!schoolId,
  });
}

export function useGetRecentStudentsForSchoolId(schoolId: string) {
  return useQuery({
    queryKey: ['schools', schoolId, 'recentStudents'],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<StudentDto[]>(
        `/schools/${schoolId}/recentStudents`,
        {
          transformResponse: [
            (data) => {
              const studentEntity: StudentEntity[] = JSON.parse(data);
              return studentEntity.map((studentEntity) =>
                StudentDto.createDtoFromEntity(studentEntity),
              );
            },
          ],
        },
      );
      return data;
    },
    enabled: !!schoolId,
  });
}

export function useRemoveUserAccess(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ emails }: { emails: string[] }) => {
      return intellitierAxios.post(`/schools/${schoolId}/removeUserAccess`, {
        emails,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['schools', schoolId, 'users'],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useAddUserAccess(schoolId: string, onSuccessCb?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      emails,
      roleNames,
    }: {
      emails: string[];
      roleNames: string[];
    }) => {
      return intellitierAxios.post(`/schools/${schoolId}/addUserAccess`, {
        emails,
        roleNames,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['schools', schoolId, 'users'],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useUpsertSchoolInfo(onSuccessCb?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ schoolInfo }: { schoolInfo: SchoolDto }) => {
      return intellitierAxios.post<SchoolEntity>(`/schools`, {
        ...schoolInfo,
      });
    },
    onSuccess: (data) => {
      const { schoolId } = data.data;
      queryClient.invalidateQueries({
        queryKey: ['schools', schoolId, 'users'],
      });

      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useBatchAddBehavioralEvent(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  return useMutation({
    mutationFn: ({
      studentIds,
      behavioralEvent,
    }: {
      studentIds: string[];
      behavioralEvent: BehavioralEventDto;
    }) => {
      return intellitierAxios.post(
        `/schools/${schoolId}/students/bulkBehavioralEvents`,
        {
          studentIds,
          behavioralEvent,
        },
      );
    },
    onSuccess: () => {
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}

export function useBatchAddIntervention(
  schoolId: string,
  onSuccessCb?: () => void,
) {
  return useMutation({
    mutationFn: ({
      studentIds,
      intervention,
    }: {
      studentIds: string[];
      intervention: InterventionDto;
    }) => {
      return intellitierAxios.post(
        `/schools/${schoolId}/students/bulkInterventions`,
        {
          studentIds,
          intervention,
        },
      );
    },
    onSuccess: () => {
      if (onSuccessCb) {
        onSuccessCb();
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(`rolling back optimistic update with id ${context}`);
    },
  });
}
