import {
  Controller,
  Control,
  FieldValues,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { DateUtilites } from '../lib/intellitierDayJs';

interface DateTimeFormFieldProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  label: string;
  defaultValue?: dayjs.Dayjs;
  disabled?: boolean;
  rules?: RegisterOptions<TFieldValues, Path<TFieldValues>>;
  onChange?: () => void;
}

const DateTimeFormField = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  defaultValue = DateUtilites.createDateInLocalDateTime(),
  disabled = false,
  rules,
  onChange,
}: DateTimeFormFieldProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          {...field}
          label={label}
          defaultValue={defaultValue}
          disabled={disabled}
          sx={{ marginRight: '.5rem' }}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
            },
          }}
          onChange={(value) => {
            field.onChange(value);
            if (onChange) onChange();
          }}
        />
      )}
    />
  );
};

export default DateTimeFormField;
