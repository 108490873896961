import { ALL_ENABLED_PERMISSIONS } from '../../constants/permissions';
import { ResourceTypes } from '../../constants/resourceTypes';
import { PermissionsDto, PermissionsEntity } from './permissions.dto';

export interface ResourceTypePermissionsEntity {
  resourceType: string;
  permissions: PermissionsEntity;
  enabledPermissions?: Array<keyof PermissionsDto>; // New field for enabled permissions
}

export class ResourceTypePermissionsDto {
  enabledPermissions?: Array<keyof PermissionsDto>;
  permissions: PermissionsDto;
  resourceType: ResourceTypes;

  constructor(resourceTypePermissionsEntity: ResourceTypePermissionsEntity) {
    this.resourceType =
      resourceTypePermissionsEntity.resourceType as ResourceTypes;
    this.permissions = PermissionsDto.createDtoFromEntity(
      resourceTypePermissionsEntity.permissions,
    );
    this.enabledPermissions =
      resourceTypePermissionsEntity.enabledPermissions ||
      ALL_ENABLED_PERMISSIONS;
  }

  static createDtoFromEntity(
    resourceTypePermissionsEntity: ResourceTypePermissionsEntity,
  ): ResourceTypePermissionsDto {
    return new ResourceTypePermissionsDto(resourceTypePermissionsEntity);
  }
}
