import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { transformApiDataToChartData } from '../../../api/dtos/transformedChartData.dto';
import { BehaviorDashboardDto } from '../../../api/dtos/behaviorDashboard.dto';
import { generateChartColors } from '../helpers/generateChartColors';

export function useChartDataAndConfig(data?: BehaviorDashboardDto) {
  const theme = useTheme();

  // Transform the time series data from the API into chart-friendly format
  const transformedData = useMemo(() => {
    if (!data?.timeSeries?.data) {
      return [];
    }
    return transformApiDataToChartData(data.timeSeries.data);
  }, [data]);

  // Generate chart colors for each data series
  const configForData = useMemo(() => {
    if (transformedData.length === 0) {
      return [];
    }

    // Get all keys except 'name' which represents data series
    const dataKeys = Object.keys(transformedData[0]).filter(
      (key) => key !== 'name',
    );

    // Generate colors based on theme and data series
    return generateChartColors(theme, dataKeys);
  }, [transformedData, theme]);

  return {
    transformedData,
    configForData,
  };
}
