import { lazy, Suspense } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import AppShell from './features/AppShell/AppShell.component';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  Typography,
} from '@mui/material';
import {
  HOME_ROUTE,
  EDIT_INTERVENTION_FOR_STUDENT_ROUTE,
  STUDENTS_ROUTE,
  EDIT_STUDENT_DETAILS_ROUTE,
  ADD_INTERVENTION_FOR_STUDENT_ROUTE,
  STUDENT_WITH_HISTORY_ROUTE,
  ADD_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE,
  EDIT_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE,
  ADD_STUDENT_DETAILS_ROUTE,
  ADMIN_ROUTE,
  SETTING_ROUTE,
  VIEW_STUDENT_DETAILS_ROUTE,
  VIEW_INTERVENTION_FOR_STUDENT_ROUTE,
  VIEW_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE,
  REQUEST_STUDENT_ACCESS_ROUTE,
  SUPPORT_ROUTE,
  PRIVACY_POLICY_ROUTE,
  ADD_BATCH_INTERVENTIONS_ROUTE,
  ADD_BATCH_BEHAVIORAL_EVENTS_ROUTE,
  ADD_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE,
  EDIT_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE,
  VIEW_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE,
  UNAUTHENTICATED_ROUTES,
  DASHBOARD_ROUTE,
  INSIGHTS_ROUTE,
} from './constants/routes';
import { PublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from './utils/NavigationClient';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTheme } from './hooks/useTheme';
import { CenteredPageCircularProgress } from './components/CenteredPageCircularProgress';
import BatchAddBehavioralEventPage from './pages/BatchAddBehavioralEvent.page';
import BatchAddInterventionPage from './pages/BatchAddIntervention.page';
import AddOrEditStudentAssessmentPage from './pages/AddOrEditStudentAssessment.page';
import { ReactTagManager } from 'react-gtm-ts';
import DashboardPage from './pages/Dashboard.page';
import InsightsPage from './pages/Insights.page';

const StudentsPage = lazy(() => import('./pages/Students.page'));
const StudentDetailsPage = lazy(() => import('./pages/StudentDetails.page'));
const AdminPage = lazy(() => import('./pages/Admin.page'));
const AddOrEditInterventionPage = lazy(
  () => import('./pages/AddOrEditIntervention.page'),
);
const AddOrEditBehavioralEventPage = lazy(
  () => import('./pages/AddOrEditBehavioralEvent.page'),
);
const SettingsPage = lazy(() => import('./pages/Settings.page'));
const SupportPage = lazy(() => import('./pages/Support.page'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy.page'));
const StudentWithHistoryPage = lazy(
  () => import('./pages/StudentWithHistory.page'),
);
const RequestStudentAccessForm = lazy(
  () => import('./features/Student/RequestStudentAccessForm.component'),
);

interface AppProps {
  pca: PublicClientApplication;
}

const queryClient = new QueryClient();

if (!import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID) {
  console.warn('Google Tag Manager ID is not defined in environment variables');
} else {
  ReactTagManager.init({
    code: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
    // consideration - in the future we can make these env variables to if we want
    debug: false,
    performance: false,
  });
}

function App({ pca }: AppProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  const { materialTheme } = useTheme();

  const isUnAuthenticatedRoute = UNAUTHENTICATED_ROUTES.has(location.pathname);

  return (
    <>
      <MsalProvider instance={pca}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={materialTheme}>
              <CssBaseline />
              <AppShell>
                <UnauthenticatedTemplate>
                  {!isUnAuthenticatedRoute && (
                    <Typography variant="h6" mt="2rem" textAlign="center">
                      Please sign up or sign in to get started.
                    </Typography>
                  )}
                  <Suspense
                    fallback={<CenteredPageCircularProgress withTopMargin />}
                  >
                    <Routes>
                      {/* this will end up being the landing page and we'll include the typogrpahy above */}
                      <Route path={HOME_ROUTE} element={null} />
                      <Route
                        path={PRIVACY_POLICY_ROUTE}
                        element={<PrivacyPolicyPage />}
                      />
                    </Routes>
                  </Suspense>
                </UnauthenticatedTemplate>

                <AuthenticatedTemplate>
                  <Suspense
                    fallback={<CenteredPageCircularProgress withTopMargin />}
                  >
                    <Routes>
                      {/* Dashboard */}
                      <Route
                        path={DASHBOARD_ROUTE}
                        element={<DashboardPage />}
                      />

                      {/* Insights */}
                      <Route path={INSIGHTS_ROUTE} element={<InsightsPage />} />

                      {/* Students */}
                      <Route path={HOME_ROUTE} element={<StudentsPage />} />
                      <Route path={STUDENTS_ROUTE} element={<StudentsPage />} />

                      {/* Student */}
                      <Route
                        path={STUDENT_WITH_HISTORY_ROUTE}
                        element={<StudentWithHistoryPage />}
                      />
                      <Route
                        path={VIEW_STUDENT_DETAILS_ROUTE}
                        element={<StudentDetailsPage mode="view" />}
                      />
                      <Route
                        path={EDIT_STUDENT_DETAILS_ROUTE}
                        element={<StudentDetailsPage mode="edit" />}
                      />
                      <Route
                        path={ADD_STUDENT_DETAILS_ROUTE}
                        element={<StudentDetailsPage mode="add" />}
                      />
                      <Route
                        path={REQUEST_STUDENT_ACCESS_ROUTE}
                        element={<RequestStudentAccessForm />}
                      />

                      {/* Intervention for Student */}
                      <Route
                        path={VIEW_INTERVENTION_FOR_STUDENT_ROUTE}
                        element={<AddOrEditInterventionPage mode="view" />}
                      />
                      <Route
                        path={ADD_INTERVENTION_FOR_STUDENT_ROUTE}
                        element={<AddOrEditInterventionPage mode="add" />}
                      />
                      <Route
                        path={EDIT_INTERVENTION_FOR_STUDENT_ROUTE}
                        element={<AddOrEditInterventionPage mode="edit" />}
                      />

                      {/* Behavioral Event for Student */}
                      <Route
                        path={VIEW_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE}
                        element={<AddOrEditBehavioralEventPage mode="view" />}
                      />
                      <Route
                        path={ADD_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE}
                        element={<AddOrEditBehavioralEventPage mode="add" />}
                      />
                      <Route
                        path={EDIT_BEHAVIORAL_EVENT_FOR_STUDENT_ROUTE}
                        element={<AddOrEditBehavioralEventPage mode="edit" />}
                      />

                      {/* Assessment Documents for Student */}
                      <Route
                        path={VIEW_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE}
                        element={<AddOrEditStudentAssessmentPage mode="view" />}
                      />
                      <Route
                        path={EDIT_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE}
                        element={<AddOrEditStudentAssessmentPage mode="edit" />}
                      />
                      <Route
                        path={ADD_STUDENT_ASSESSMENT_FOR_STUDENT_ROUTE}
                        element={<AddOrEditStudentAssessmentPage mode="add" />}
                      />

                      {/* Batch Routes */}
                      <Route
                        path={ADD_BATCH_BEHAVIORAL_EVENTS_ROUTE}
                        element={<BatchAddBehavioralEventPage />}
                      />
                      <Route
                        path={ADD_BATCH_INTERVENTIONS_ROUTE}
                        element={<BatchAddInterventionPage />}
                      />

                      {/* Settings and Admin Routes */}
                      <Route path={SETTING_ROUTE} element={<SettingsPage />} />
                      <Route path={ADMIN_ROUTE} element={<AdminPage />} />
                      <Route path={SUPPORT_ROUTE} element={<SupportPage />} />
                      <Route
                        path={PRIVACY_POLICY_ROUTE}
                        element={<PrivacyPolicyPage />}
                      />
                    </Routes>
                  </Suspense>
                </AuthenticatedTemplate>
              </AppShell>
              {import.meta.env.VITE_REACT_QUERY_DEV_TOOLS_ENABLED ===
                'true' && <ReactQueryDevtools initialIsOpen />}
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </MsalProvider>
    </>
  );
}

export default App;
