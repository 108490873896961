import React, { useState, useCallback, memo, useMemo } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid2,
  Button,
  useTheme,
} from '@mui/material';
import { InsightDetailsDto } from '../../../api/dtos/insightDetails.dto';

// Insight Summary Card Component
interface InsightSummaryCardProps {
  title: string;
  cards: SummaryCardProps[];
  insightDetails?: InsightDetailsDto;
}

const InsightSummaryCard: React.FC<InsightSummaryCardProps> = ({
  title,
  cards,
  insightDetails,
}) => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);

  const cardGridSize = useMemo(() => {
    // Ensure minimum column width of 3 units
    return Math.max(12 / Math.max(cards.length, 1), 3);
  }, [cards.length]);

  const toggleDetails = useCallback(() => {
    setShowDetails((prev) => !prev);
  }, []);

  return (
    <Box
      sx={{
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: 'solid',
        p: 2,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: showDetails ? 400 : 'auto',
      }}
    >
      <CardTitle title={title} />

      {/* Summary Cards */}
      <Grid2 container spacing={2} sx={{ flexGrow: 1 }}>
        {cards.map((card, index) => (
          <Grid2
            key={`${card.title}-${index}`}
            size={{ xs: cardGridSize }}
            sx={{ display: 'flex' }}
          >
            <SummaryCard {...card} />
          </Grid2>
        ))}
      </Grid2>

      {/* Toggle Details Button */}
      {insightDetails && (
        <Box mt={2} textAlign="center">
          <Button
            size="small"
            sx={{
              textTransform: 'none',
              color: theme.palette.primary.main,
              fontWeight: 'bold',
            }}
            onClick={toggleDetails}
          >
            {showDetails ? 'Hide Details' : 'View Details'}
          </Button>
        </Box>
      )}

      {/* Insight Details Section */}
      {showDetails && insightDetails && (
        <InsightDetailsSection insightDetails={insightDetails} />
      )}
    </Box>
  );
};

export default InsightSummaryCard;

const CardTitle: React.FC<{ title: string }> = memo(function CardTitle({
  title,
}) {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" gap={1} mb={2}>
      <Typography variant="h6" color={theme.palette.text.primary}>
        {title}
      </Typography>
    </Box>
  );
});
CardTitle.displayName = 'CardTitle';

interface SummaryCardProps {
  title: string;
  value: number | string;
}

const SummaryCard: React.FC<SummaryCardProps> = memo(function SummaryCard({
  title,
  value,
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.default,
        textAlign: 'center',
        p: 2,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: 165,
        }}
      >
        <Typography variant="h6" color={theme.palette.text.primary}>
          {title}
        </Typography>
        <Typography
          variant="h4"
          fontWeight="bold"
          color={theme.palette.text.primary}
          mt={1}
        >
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
});
SummaryCard.displayName = 'SummaryCard';

const InsightDetailsSection: React.FC<{ insightDetails: InsightDetailsDto }> =
  memo(({ insightDetails }) => {
    const theme = useTheme();

    return (
      <Box
        mt={2}
        p={2}
        width="100%"
        sx={{
          borderColor: theme.palette.divider,
          borderWidth: 1,
          borderStyle: 'solid',
          maxHeight: 250,
          overflowY: 'auto',
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
        }}
      >
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          color={theme.palette.text.primary}
          textAlign="left"
        >
          {insightDetails.title}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          textAlign="left"
        >
          {insightDetails.description}
        </Typography>
        <Box mt={1}>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            color={theme.palette.text.primary}
            textAlign="left"
          >
            Recommendation:
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            textAlign="left"
          >
            {insightDetails.recommendation}
          </Typography>
        </Box>
      </Box>
    );
  });
InsightDetailsSection.displayName = 'InsightDetailsSection';
