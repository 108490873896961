import { Dayjs } from 'dayjs';
import { DateUtilites } from '../../lib/intellitierDayJs';
import { UserDto } from './user.dto';
export interface InterventionNoteEntity {
  interventionNoteId: string;
  description: string;
  progressRating: string;
  createdBy: string;
  dateCreated: string;
  createdByUser: UserDto;
  updatedBy: string;
  dateUpdated: string;
  updatedByUser: UserDto;
}

export class InterventionNoteDto {
  formattedDateUpdated?: string;
  dateUpdated?: Dayjs;
  updatedByUser?: UserDto;
  updatedBy?: string;
  formattedDateCreated?: string;
  dateCreated?: Dayjs;
  createdByUser?: UserDto;
  createdBy?: string;
  progressRating: string;
  description: string;
  interventionNoteId?: string;

  constructor(interventionNoteEntity: InterventionNoteEntity) {
    this.interventionNoteId = interventionNoteEntity.interventionNoteId;
    this.description = interventionNoteEntity.description;
    this.progressRating = interventionNoteEntity.progressRating;
    this.createdBy = interventionNoteEntity.createdBy;
    this.dateCreated = DateUtilites.createLocalTimeFromUtcDate(
      interventionNoteEntity.dateCreated,
    );
    this.formattedDateCreated = DateUtilites.formatDateToLocalDateTime(
      this.dateCreated,
    );
    this.createdByUser = interventionNoteEntity.createdByUser;
    this.updatedBy = interventionNoteEntity.updatedBy;
    this.dateUpdated = DateUtilites.createLocalTimeFromUtcDate(
      interventionNoteEntity.dateUpdated,
    );
    this.formattedDateUpdated = DateUtilites.formatDateToLocalDateTime(
      this.dateUpdated,
    );
    this.updatedByUser = interventionNoteEntity.updatedByUser;
  }

  static createDtoFromEntity(
    interventionNoteEntity: InterventionNoteEntity,
  ): InterventionNoteDto {
    return new InterventionNoteDto(interventionNoteEntity);
  }
}
