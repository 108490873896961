import { Paper } from '@mui/material';
import { StackedBarChart } from './components/StackedBarChart.component';
import { TotalsAreaChart } from './components/TotalsAreaChart.component';
import { useMemo } from 'react';
import { BehaviorDashboardDto } from '../../api/dtos/behaviorDashboard.dto';

type DashboardBarChartsProps = {
  selectedBehaviorType: string;
  selectedIntensity: string;
  isDataLoading: boolean;
  sebDashboardData: BehaviorDashboardDto | undefined;
  shouldShowBarChartToplabels: boolean;
};

const DashboardBarCharts: React.FC<DashboardBarChartsProps> = ({
  selectedBehaviorType,
  selectedIntensity,
  isDataLoading,
  sebDashboardData,
  shouldShowBarChartToplabels,
}) => {
  const chartTitle = useMemo(() => {
    if (!selectedBehaviorType && !selectedIntensity) {
      return 'Total Behavior Events';
    }

    if (selectedBehaviorType && !selectedIntensity) {
      return `${selectedBehaviorType} with Intensities`;
    }

    return `${selectedIntensity} Intensity  ${selectedBehaviorType} events by Duration`;
  }, [selectedBehaviorType, selectedIntensity]);

  const chartComponent = useMemo(() => {
    if (!selectedBehaviorType && !selectedIntensity) {
      return (
        <TotalsAreaChart
          isDataLoading={isDataLoading}
          title={chartTitle}
          data={sebDashboardData}
          xKey="name"
          shouldShowTopLabels={shouldShowBarChartToplabels}
        />
      );
    }

    return (
      <StackedBarChart
        isDataLoading={isDataLoading}
        data={sebDashboardData}
        xKey="name"
        title={chartTitle}
        height={400}
        width="100%"
      />
    );
  }, [
    shouldShowBarChartToplabels,
    selectedBehaviorType,
    selectedIntensity,
    isDataLoading,
    sebDashboardData,
    chartTitle,
  ]);

  return (
    <Paper elevation={4} sx={{ padding: 2, marginBottom: 4, height: 600 }}>
      {chartComponent}
    </Paper>
  );
};

export default DashboardBarCharts;
