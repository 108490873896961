import { SchoolInsightDto, SchoolInsightEntity } from './schoolInsight.dto';

export interface SchoolInsightsEntity {
  schoolInsights: SchoolInsightEntity[];
}

export class SchoolInsightsDto {
  schoolInsights: SchoolInsightDto[];

  constructor(entity: SchoolInsightsEntity) {
    this.schoolInsights = entity.schoolInsights.map(
      (insight) => new SchoolInsightDto(insight),
    );
  }

  static createDtoFromEntity(entity: SchoolInsightsEntity): SchoolInsightsDto {
    return new SchoolInsightsDto(entity);
  }
}
