/**
 * Converts a label into a "safe" object key by normalizing casing
 * and removing most special characters (except dashes).
 *
 * - Converts all letters to lowercase
 * - Removes special characters except for letters, digits, underscores, spaces, and dashes
 * - Trims leading/trailing spaces
 * - Replaces any remaining space with an underscore
 *
 * @param label - The original label to transform
 * @returns A safe key string (e.g. "1-5_minutes")
 */
export function toSafeKey(label: string): string {
  return label
    .toLowerCase()
    .replace(/[^\w\s-+]/g, '') // Allow alphanumeric, underscores, spaces, dashes, and plus signs
    .trim()
    .replace(/\s+/g, '_');
}

/**
 * Converts a safe key back into a more human-readable label.
 * - Splits on underscores into segments
 * - If a segment is numeric ranges separated by a dash (e.g., "1-5"), it’s left as-is
 * - Otherwise, capitalizes the first letter of the segment
 * - Joins segments with a space
 *
 * @param safeKey - The safe key to transform back (e.g., "1-5_minutes")
 * @returns A human-readable label string (e.g., "1-5 Minutes")
 */
export function fromSafeKey(safeKey: string): string {
  return safeKey
    .split('_')
    .map((segment) => {
      // Detect a numeric range with a dash, leave it unchanged
      if (/^\d+-\d+$/.test(segment)) {
        return segment;
      }

      // Detect a "3+ Hours" format (e.g., "3+" should remain unchanged)
      if (/^\d+\+$/.test(segment)) {
        return segment;
      }

      // Otherwise, capitalize the first letter
      return segment.charAt(0).toUpperCase() + segment.slice(1);
    })
    .join(' ');
}
