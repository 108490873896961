export interface TextHighlightInsightSummaryCardEntity {
  title: string;
  cardType: string;
  body: string;
}

export class TextHighlightInsightSummaryCardDto {
  title: string;
  cardType: string;
  body: string;

  constructor(entity: TextHighlightInsightSummaryCardEntity) {
    this.title = entity.title;
    this.cardType = entity.cardType;
    this.body = entity.body;
  }

  static createDtoFromEntity(
    entity: TextHighlightInsightSummaryCardEntity,
  ): TextHighlightInsightSummaryCardDto {
    return new TextHighlightInsightSummaryCardDto(entity);
  }
}
