import React, { memo } from 'react';
import { NumberHighlightInsightSummaryCardDto } from '../../../api/dtos/numberHighlightInsightSummaryCard.dto';
import InsightSummaryCard from './InsightSummaryCard.component';
import { InsightDetailsDto } from '../../../api/dtos/insightDetails.dto';
import NoDataAvailableFallbackCard from './NoDataAvailableFallbackCard.component';

interface NumberHighlightInsightProps {
  insight?: NumberHighlightInsightSummaryCardDto;
  insightDetails?: InsightDetailsDto;
}

export const NumberHighlightInsightSummaryCard: React.FC<NumberHighlightInsightProps> =
  memo(function NumberHighlightInsightSummaryCard({ insight, insightDetails }) {
    if (!insight || !insightDetails) {
      return <NoDataAvailableFallbackCard />;
    }

    return (
      <InsightSummaryCard
        title={insight?.title || 'Unknown Title'}
        cards={[
          {
            title: insight?.numberTitle || 'Unknown Number',
            value: insight?.number ?? 'N/A',
          },
        ]}
        insightDetails={insightDetails}
      />
    );
  });

NumberHighlightInsightSummaryCard.displayName =
  'NumberHighlightInsightSummaryCard';
export default NumberHighlightInsightSummaryCard;
