import { useState, useMemo, useCallback, SetStateAction } from 'react';
import { Paper, Typography } from '@mui/material';
import { FullWidthPageContainer } from '../components/FullWidthPageContainer';
import { useGetSebDashboardData } from '../api/services/dashboard.service';
import { useClientStore } from '../store/store';
import { useGetPossibleValuesFromEntityConfig } from '../store/entityConfigHelpers';
import DashboardBarCharts from '../features/Dashboard/DashboardBarCharts';
import { DateRanges } from '../features/Dashboard/types';
import { intellierDayJs } from '../lib/intellitierDayJs';
import { DashboardFilters } from '../features/Dashboard/DashboardFilters.component';
import DashboardPieCharts from '../features/Dashboard/DashboardPieCharts';

const dateRanges: DateRanges = {
  Today: [intellierDayJs().startOf('day'), intellierDayJs().endOf('day')],
  'Last 7 Days': [
    intellierDayJs().subtract(7, 'days').startOf('day'),
    intellierDayJs().endOf('day'),
  ],
  'Last 30 Days': [
    intellierDayJs().subtract(30, 'days').startOf('day'),
    intellierDayJs().endOf('day'),
  ],
  'Last 6 Months': [
    intellierDayJs().subtract(6, 'months').startOf('day'),
    intellierDayJs().endOf('day'),
  ],
  'Last 12 Months': [
    intellierDayJs().subtract(12, 'months').startOf('day'),
    intellierDayJs().endOf('day'),
  ],
};

const DashboardPage = () => {
  const selectedSchoolId = useClientStore.use.selectedSchoolId();

  // State for filters
  const [selectedBehaviorType, setSelectedBehaviorType] = useState('');
  const [selectedIntensity, setSelectedIntensity] = useState('');
  const [selectedDateRange, setSelectedDateRange] =
    useState<keyof typeof dateRanges>('Last 30 Days');

  // Memoize the derived date values
  const [startDate, endDate] = useMemo(
    () => dateRanges[selectedDateRange],
    [selectedDateRange],
  );

  // Memoize functions to prevent unnecessary re-renders
  const handleBehaviorTypeChange = useCallback(
    (value: string) => setSelectedBehaviorType(value),
    [],
  );
  const handleIntensityChange = useCallback(
    (value: string) => setSelectedIntensity(value),
    [],
  );
  const handleDateRangeChange = useCallback(
    (value: SetStateAction<keyof DateRanges>) => setSelectedDateRange(value),
    [],
  );

  // Fetch options
  const behaviorOptions = useGetPossibleValuesFromEntityConfig(
    'BehavioralEventDTO',
    'behavior',
  );
  const intensityOptions = useGetPossibleValuesFromEntityConfig(
    'BehavioralEventDTO',
    'intensity',
  );

  // Fetch API data
  const { data: sebDashboardData, isLoading: isDataLoading } =
    useGetSebDashboardData(
      selectedSchoolId,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      selectedBehaviorType,
      selectedIntensity,
    );

  const hasLargeTimeRange = selectedDateRange.includes('Months');

  return (
    <FullWidthPageContainer>
      <Paper
        elevation={4}
        sx={{
          padding: {
            xs: 2,
            sm: 2,
            md: 4,
          },
          margin: '16px auto',
          maxWidth: 2100,
        }}
      >
        <Typography variant="h5" gutterBottom padding={2}>
          Behavioral Events
        </Typography>

        <DashboardFilters
          behaviorOptions={behaviorOptions}
          intensityOptions={intensityOptions}
          selectedBehaviorType={selectedBehaviorType}
          setSelectedBehaviorType={handleBehaviorTypeChange}
          selectedIntensity={selectedIntensity}
          setSelectedIntensity={handleIntensityChange}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={handleDateRangeChange}
          dateRanges={dateRanges}
        />

        <DashboardBarCharts
          selectedBehaviorType={selectedBehaviorType}
          selectedIntensity={selectedIntensity}
          isDataLoading={isDataLoading}
          sebDashboardData={sebDashboardData}
          shouldShowBarChartToplabels={false} //{!hasLargeTimeRange}
        />

        <DashboardPieCharts
          isDataLoading={isDataLoading}
          selectedBehaviorType={selectedBehaviorType}
          sebDashboardData={sebDashboardData}
        />
      </Paper>
    </FullWidthPageContainer>
  );
};

export default DashboardPage;
