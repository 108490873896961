import React from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { OneDChartDto } from '../../../api/dtos/oneDChart.dto';
import { Typography, useTheme, Grid2 } from '@mui/material';
import { generateChartColors } from '../helpers/generateChartColors';
import { CenteredPageCircularProgress } from '../../../components/CenteredPageCircularProgress';
import ChartTooltip from './ChartTooltip';

type AntecedentsPieChartProps = {
  data?: OneDChartDto['data'];
  width?: number;
  height?: number;
  outerRadius?: number;
  isDataLoading?: boolean;
};

export const AntecedentsPieChart: React.FC<AntecedentsPieChartProps> = ({
  data,
  width = 400,
  height = 400,
  outerRadius = 150,
  isDataLoading = false,
}) => {
  const theme = useTheme();

  const ChartContent = () => {
    if (isDataLoading) {
      return <CenteredPageCircularProgress withTopMargin />;
    }

    if (!data || data.length === 0) {
      return (
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{ fontWeight: 'bold' }}
          textAlign="center"
        >
          No data available
        </Typography>
      );
    }

    const dataKeys = data?.map((d) => d.label) || [];
    const colors = generateChartColors(theme, dataKeys);

    return (
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="label"
          cx="50%"
          cy="50%"
          outerRadius={outerRadius}
          fill="#FFF"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index].fill} />
          ))}
        </Pie>
        <Tooltip content={<ChartTooltip />} />
      </PieChart>
    );
  };

  return (
    <Grid2 container direction="column" alignItems="center" spacing={2}>
      <Grid2>
        <Typography variant="h6">Antecedent Distribution</Typography>
      </Grid2>
      <Grid2>
        <ChartContent />
      </Grid2>
    </Grid2>
  );
};

export default AntecedentsPieChart;
