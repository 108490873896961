import React from 'react';
import { Box, Typography } from '@mui/material';

interface NoDataAvailableFallbackCardProps {
  message?: string;
}

const NoDataAvailableFallbackCard: React.FC<
  NoDataAvailableFallbackCardProps
> = ({ message = 'No insights found.' }) => {
  return (
    <Box textAlign="center" p={2}>
      <Typography variant="body2" color="textSecondary">
        {message}
      </Typography>
    </Box>
  );
};

export default NoDataAvailableFallbackCard;
