import {
  ResourceTypePermissionsDto,
  ResourceTypePermissionsEntity,
} from './resourceTypePermissions.dto';

export interface UserPermissionsEntity {
  resourceTypePermissions: ResourceTypePermissionsEntity[];
}

export class UserPermissionsDto {
  resourceTypePermissions: ResourceTypePermissionsDto[];

  constructor(userPermissionsEntity: UserPermissionsEntity) {
    this.resourceTypePermissions =
      userPermissionsEntity.resourceTypePermissions.map(
        (resourceTypePermissionsEntity) =>
          ResourceTypePermissionsDto.createDtoFromEntity(
            resourceTypePermissionsEntity,
          ),
      );
  }

  static createDtoFromEntity(
    userPermissionsEntity: UserPermissionsEntity,
  ): UserPermissionsDto {
    return new UserPermissionsDto(userPermissionsEntity);
  }
}
