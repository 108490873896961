import { useQuery } from '@tanstack/react-query';
import intellitierAxios from '../../lib/intellitierAxios';
import {
  SchoolInsightsDto,
  SchoolInsightsEntity,
} from '../dtos/schoolInsights.dto';

export function useGetInsightsData(schoolId: string) {
  return useQuery({
    queryKey: ['schools', schoolId, 'dashboard', 'insights'],
    queryFn: async () => {
      const { data } = await intellitierAxios.get<SchoolInsightsDto>(
        `/schools/${schoolId}/insights`,
        {
          transformResponse: [
            (data) => {
              const schoolInsightsEntity: SchoolInsightsEntity =
                JSON.parse(data);
              return SchoolInsightsDto.createDtoFromEntity(
                schoolInsightsEntity,
              );
            },
          ],
        },
      );
      return data;
    },
    enabled: !!schoolId,
    staleTime: 2 * 60 * 1000, // 2 min: Data considered fresh for 2 minutes
  });
}
