export interface SeriesEntryEntity {
  label: string;
  value: number;
}

export class SeriesEntryDto {
  label: string;
  value: number;

  constructor(seriesEntryEntity: SeriesEntryEntity) {
    this.label = seriesEntryEntity.label;
    this.value = seriesEntryEntity.value;
  }

  static createDtoFromEntity(
    seriesEntryEntity: SeriesEntryEntity,
  ): SeriesEntryDto {
    return new SeriesEntryDto(seriesEntryEntity);
  }
}
