import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { TooltipProps } from 'recharts';

const ChartTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  const theme = useTheme();

  if (!active || !payload || payload.length === 0) return null;

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        color: '#fff',
        padding: '10px',
        borderRadius: '8px',
        boxShadow: theme.shadows[3],
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>
      {payload.map((entry, index) => (
        <Box key={index} display="flex" alignItems="center" mt={0.5}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: entry.payload.fill || entry.color || '#ccc', // Use Pie's color
              borderRadius: '2px',
              marginRight: '6px',
            }}
          />
          <Typography variant="body2">{`${entry.name}: ${entry.value}`}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ChartTooltip;
