export interface ThreeNumberHighlightInsightSummaryCardEntity {
  title: string;
  cardType: string;
  numberTitle1: string;
  number1: number;
  numberTitle2: string;
  number2: number;
  numberTitle3: string;
  number3: number;
}

export class ThreeNumberHighlightInsightSummaryCardDto {
  title: string;
  cardType: string;
  numberTitle1: string;
  number1: number;
  numberTitle2: string;
  number2: number;
  numberTitle3: string;
  number3: number;

  constructor(entity: ThreeNumberHighlightInsightSummaryCardEntity) {
    this.title = entity.title;
    this.cardType = entity.cardType;
    this.numberTitle1 = entity.numberTitle1;
    this.number1 = entity.number1;
    this.numberTitle2 = entity.numberTitle2;
    this.number2 = entity.number2;
    this.numberTitle3 = entity.numberTitle3;
    this.number3 = entity.number3;
  }

  static createDtoFromEntity(
    entity: ThreeNumberHighlightInsightSummaryCardEntity,
  ): ThreeNumberHighlightInsightSummaryCardDto {
    return new ThreeNumberHighlightInsightSummaryCardDto(entity);
  }
}
