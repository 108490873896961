import { createTheme } from '@mui/material/styles';

// Define the light theme settings
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0073e6', // Primary Blue
    },
    secondary: {
      main: '#9c27b0', // Purple as Secondary Color
    },
    background: {
      default: '#ffffff', // Light neutral background
      paper: '#fafafa', // White for cards and paper components
    },
    text: {
      primary: '#2c3e50', // Dark grey text for readability
    },
  },
  custom: {
    message: {
      user: '#d6eaf8', // Neutral light grey for user messages
      assistant: '#f5f5f5', // Soft lavender for assistant messages
    },
    chart: {
      baseColorOne: '#00A6FB',
      baseColorTwo: '#F6C85F',
      baseColorThree: '#6F4E7C',
      baseColorFour: '#9DD866',
      baseColorFive: '#FF5E3B',
    },
  },
});

// Define the dark theme settings
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0f83f0',
    },
    secondary: {
      main: '#f07c0f',
    },
    background: {
      default: '#192231',
      paper: '#141b27',
    },
    text: {
      primary: '#ffffff', // Light text for readability in dark mode
    },
  },
  custom: {
    message: {
      user: '#34495E',
      assistant: '#24344d',
    },
    chart: {
      baseColorOne: '#00A6FB',
      baseColorTwo: '#F6C85F',
      baseColorThree: '#6F4E7C',
      baseColorFour: '#9DD866',
      baseColorFive: '#FF5E3B',
    },
  },
});

export { lightTheme, darkTheme };
