import React from 'react';
import { SchoolInsightDto } from '../../api/dtos/schoolInsight.dto';
import NumberHighlightInsightSummaryCard from './components/NumberHighlightInsightSummaryCard.component';
import TextHighlightInsightSummaryCard from './components/TextHighlightInsightSummaryCard.component';
import { NumberHighlightInsightSummaryCardDto } from '../../api/dtos/numberHighlightInsightSummaryCard.dto';
import { TextHighlightInsightSummaryCardDto } from '../../api/dtos/textHighlightInsightSummaryCard.dto';
import { TwoNumberHighlightInsightSummaryCardDto } from '../../api/dtos/twoNumberHighlightInsightSummaryCard.dto';
import { ThreeNumberHighlightInsightSummaryCardDto } from '../../api/dtos/threeNumberHighlightInsightSummaryCard.dto';
import { TwoNumberHighlightInsightSummaryCard } from './components/TwoNumberHighlightInsightSummaryCard.component';
import ThreeNumberHighlightInsightSummaryCard from './components/ThreeNumberHighlightInsightSummaryCard.component';

interface InsightCardProps {
  schoolInsight: SchoolInsightDto;
}

export const InsightCard: React.FC<InsightCardProps> = ({ schoolInsight }) => {
  const { insightSummaryCard, insightDetails } = schoolInsight;

  // if we start adding more types, we should change this to a component map
  switch (insightSummaryCard.cardType) {
    case 'NUMBER_HIGHLIGHT':
      return (
        <NumberHighlightInsightSummaryCard
          insightDetails={insightDetails}
          insight={insightSummaryCard as NumberHighlightInsightSummaryCardDto}
        />
      );
    case 'TEXT_HIGHLIGHT':
      return (
        <TextHighlightInsightSummaryCard
          insight={insightSummaryCard as TextHighlightInsightSummaryCardDto}
          insightDetails={insightDetails}
        />
      );
    case 'TWO_NUMBER_HIGHLIGHT':
      return (
        <TwoNumberHighlightInsightSummaryCard
          insight={
            insightSummaryCard as TwoNumberHighlightInsightSummaryCardDto
          }
          insightDetails={insightDetails}
        />
      );
    case 'THREE_NUMBER_HIGHLIGHT':
      return (
        <ThreeNumberHighlightInsightSummaryCard
          insight={
            insightSummaryCard as ThreeNumberHighlightInsightSummaryCardDto
          }
          insightDetails={insightDetails}
        />
      );
    default:
      return null;
  }
};
