import { Grid2 } from '@mui/material';
import { useGetInsightsData } from '../api/services/insights.service';
import { useClientStore } from '../store/store';
import { InsightCard } from '../features/Insights/InsightCard.component';
import { FullWidthPageContainer } from '../components/FullWidthPageContainer';
import { CenteredPageCircularProgress } from '../components/CenteredPageCircularProgress';
import NoDataAvailableFallbackCard from '../features/Insights/components/NoDataAvailableFallbackCard.component';

const InsightsPage = () => {
  const selectedSchoolId = useClientStore.use.selectedSchoolId();

  const {
    data,
    isFetched,
    isLoading: isLoadingInsightdata,
  } = useGetInsightsData(selectedSchoolId);

  if (isLoadingInsightdata) {
    return (
      <FullWidthPageContainer>
        <CenteredPageCircularProgress withTopMargin />
      </FullWidthPageContainer>
    );
  }

  if (isFetched && !data?.schoolInsights?.length) {
    return (
      <FullWidthPageContainer>
        <NoDataAvailableFallbackCard message="No insights available for this school." />
      </FullWidthPageContainer>
    );
  }

  return (
    <FullWidthPageContainer>
      <Grid2 container spacing={3}>
        {data?.schoolInsights.map((insight, index) => {
          return (
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 12,
                lg: 6,
                xl: 4,
              }}
              key={`insight-${insight.insightSummaryCard.title}-${index}`}
              sx={{ display: 'flex' }}
            >
              <InsightCard schoolInsight={insight} />
            </Grid2>
          );
        })}
      </Grid2>
    </FullWidthPageContainer>
  );
};

export default InsightsPage;
