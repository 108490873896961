import { SxProps, Theme, Grid2 as Grid } from '@mui/material';

interface PageContainerProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const FullWidthPageContainer: React.FC<PageContainerProps> = ({
  children,
  sx = {
    p: 3,
  },
}) => {
  return (
    <Grid sx={sx} container justifyContent="center" spacing={2}>
      <Grid
        size={{
          xs: 12,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};
