import React, { memo } from 'react';
import { TwoNumberHighlightInsightSummaryCardDto } from '../../../api/dtos/twoNumberHighlightInsightSummaryCard.dto';
import InsightSummaryCard from './InsightSummaryCard.component';
import { InsightDetailsDto } from '../../../api/dtos/insightDetails.dto';
import NoDataAvailableFallbackCard from './NoDataAvailableFallbackCard.component';

interface TwoNumberHighlightInsightSummaryCardProps {
  insight?: TwoNumberHighlightInsightSummaryCardDto;
  insightDetails?: InsightDetailsDto;
}

export const TwoNumberHighlightInsightSummaryCard: React.FC<TwoNumberHighlightInsightSummaryCardProps> =
  memo(function TwoNumberHighlightInsightSummaryCard({
    insight,
    insightDetails,
  }) {
    if (!insight || !insightDetails) {
      return <NoDataAvailableFallbackCard />;
    }

    return (
      <InsightSummaryCard
        title={insight?.title || 'Unknown Title'}
        cards={[
          {
            title: insight?.numberTitle1 || 'Unknown',
            value: insight?.number1 ?? 'N/A',
          },
          {
            title: insight?.numberTitle2 || 'Unknown',
            value: insight?.number2 ?? 'N/A',
          },
        ]}
        insightDetails={insightDetails}
      />
    );
  });

TwoNumberHighlightInsightSummaryCard.displayName =
  'TwoNumberHighlightInsightSummaryCard';

export default TwoNumberHighlightInsightSummaryCard;
