import { DataPoint2DDto, DataPoint2DEntity } from './dataPoint2.dto';

export interface TwoDChartEntity {
  chartName: string;
  data: DataPoint2DEntity[];
  xaxisName: string;
  yaxisName: string;
}

export class TwoDChartDto {
  chartName: string;
  data: DataPoint2DDto[];
  xaxisName: string;
  yaxisName: string;

  constructor(twoDChartEntity: TwoDChartEntity) {
    this.chartName = twoDChartEntity.chartName;
    this.xaxisName = twoDChartEntity.xaxisName;
    this.yaxisName = twoDChartEntity.yaxisName;
    this.data = twoDChartEntity.data.map(
      (dataPoint) => new DataPoint2DDto(dataPoint),
    );
  }

  static createDtoFromEntity(twoDChartEntity: TwoDChartEntity): TwoDChartDto {
    return new TwoDChartDto(twoDChartEntity);
  }
}
